import React, { useState, useEffect } from "react";
import Calendar from "../Cal/Calendar";
import Cards from "./Cards/Cards";
import LogoutButton from "../LogoutButton";
import Live from "../Live";
import NameForm from "./NameForm";

const User = ({ userInfo, setUserInfo, socket }) => {
  const date = new Date();

  // initalize currentActiveDate state
  const [currectActiveDate, setCurrentActiveDate] = useState(date);
  const [showModal, setShowModal] = useState({ open: false, date: null });

  useEffect(() => {
    if (socket.connected) {
      console.log("socket connected");
      socket.on("events updated", (data) => {
        const userName = userInfo.userName;
        const updateEvents = async () => {
          const updatedRes = await fetch(
            "https://node.hadsraddad.com/api/updateEvents",
            {
              method: "POST",
              body: JSON.stringify({
                userName: userName,
              }),
              headers: {
                Accept: "*/*",
                "Content-Type": "application/json",
              },
            }
          );
          const json = await updatedRes.json();
          json.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          });
          setUserInfo((prevInfo) => ({
            ...prevInfo,
            eventdates: json,
          }));
        };
        updateEvents();
      });
    }
  }, [userInfo.userName, socket, setUserInfo]);

  return (
    <div className="App">
      {userInfo.newuser || typeof userInfo.newuser === "undefined" ? (
        <NameForm setUserInfo={setUserInfo} userInfo={userInfo} />
      ) : (
        ""
      )}
      {socket.connected && <Live admin={userInfo.admin} />}
      {showModal.open && (
        <div className="modal">
          <h1>{new Date(showModal.date).toDateString()}</h1>
          <h2>Not available</h2>
          <p style={{ color: "black" }}>
            Please select date later than{" "}
            {new Date(Date.now() + 12096e5).toDateString()}
          </p>
        </div>
      )}
      <Calendar
        setUserInfo={setUserInfo}
        userInfo={userInfo}
        currentActiveDate={currectActiveDate}
        setCurrentActiveDate={setCurrentActiveDate}
        setShowModal={setShowModal}
      />

      <Cards
        events={userInfo.eventdates}
        currentActiveDate={currectActiveDate}
        userName={userInfo.userName}
      />

      {/* <h1 onClick={() => socket.emit("send message", "hello")}>
        click to tell server hi
      </h1> */}
      <LogoutButton />
    </div>
  );
};

export default User;
