import React from "react";

const AdminCard = ({
  event,
  index,
  id,
  allEvents,
  setAllEvents,
  socket,
  setEventEditer,
  adminStorage,
  setAdminStorage,
}) => {
  const eTitle = event.eventTitle;

  //return css class based on event title
  let cardClass;
  switch (eTitle) {
    case "Airport":
      cardClass = "blue-glow card";
      break;
    case "Keyroom":
      cardClass = "yellow-glow card";
      break;
    case "Photos":
      cardClass = "pink-glow card";
      break;
    case "Inprocessing":
      cardClass = "green-glow card";
      break;
    case "Graduation":
      cardClass = "purple-glow card";
      break;
    case "After Hours":
      cardClass = "aqua-glow card";
      break;
    case "Department Help":
      cardClass = "orange-glow card";
      break;
    default:
      cardClass = null;
  }
  const cardDate = new Date(event.date).toDateString();
  const deleteItem = () => {
    const dEvents = async () => {
      let idObj = { _id: id };
      const resp = await fetch("https://node.hadsraddad.com/api/deleteEvents", {
        method: "POST",
        body: JSON.stringify(idObj),
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });
      const eventsJson = await resp.json();
      eventsJson.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });
      if (adminStorage.userLoaded) {
        setAdminStorage((prev) => ({ ...prev, data: eventsJson }));
        let newEvents = allEvents.filter((el) => el._id !== id);
        setAllEvents((prev) => newEvents);
      } else {
        setAllEvents(eventsJson);
      }
    };
    socket.emit("event created", "deleted");
    dEvents();
  };

  const cardClick = (e) => {
    if (e.target.localName !== "button") {
      setEventEditer((prev) => ({ open: !prev.open, event: event }));
    }
  };

  return (
    <div
      style={
        index === 0
          ? {
              marginTop: 0,
            }
          : {}
      }
      className={cardClass}
      onClick={cardClick}
    >
      <h3 className="event-title">{event.eventTitle}</h3>
      <button onClick={deleteItem} className="delete-button">
        x
      </button>
      <div className="event-users">
        {event.assigned.map((emp, idx) => {
          return (
            <dl key={idx}>
              <dt>{emp.task} :</dt>
              <dd>{emp.user}</dd>
            </dl>
          );
        })}
      </div>
      <p style={{ width: "60%" }}>{event.info}</p>
      <span className="card-date">{cardDate}</span>
    </div>
  );
};

export default AdminCard;
