import React, { useState } from "react";
import AllUsers from "./AllUsers";

const Menu = ({
  setAdminInfo,
  adminStorage,
  setAdminStorage,
  allEvents,
  setAllEvents,
}) => {
  const [open, setOpen] = useState(false);
  const [allUsers, setAllUsers] = useState([]);

  const getAllUsers = async () => {
    const resp = await fetch("https://node.hadsraddad.com/api/getAllUsers", {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    });
    const json = await resp.json();
    json.sort((a, b) => (a.userName[0] > b.userName[0] ? 1 : -1));
    setAllUsers((prev) => json.sort());
  };

  const openMenu = () => {
    getAllUsers();
    setOpen((prev) => !prev);
  };

  const closeUser = () => {
    setAllEvents(() => adminStorage.data);
    setAdminInfo((prev) => ({ ...prev, requesteddates: [] }));
    setAdminStorage(() => ({ data: "", userLoaded: false }));
  };

  return (
    <div className={`top-menu`}>
      <div className="hamburger" onClick={openMenu}>
        <img className="ham-button" src="./assets/ham.png" alt="hamburger" />
      </div>
      {allUsers && open && (
        <AllUsers
          setAdminInfo={setAdminInfo}
          userList={allUsers}
          setAllEvents={setAllEvents}
          allEvents={allEvents}
          setAdminStorage={setAdminStorage}
          setOpen={setOpen}
        />
      )}
      {adminStorage.userLoaded && (
        <div className="user-view">
          <h2>{adminStorage.user}</h2>
        </div>
      )}
      {adminStorage.userLoaded && (
        <div onClick={closeUser} className="close-user">
          CLOSE X
        </div>
      )}
    </div>
  );
};

export default Menu;
