import React from "react";

const Live = ({ socket, admin }) => {
  return (
    <div className="live-banner" style={admin ? {} : { top: "0" }}>
      <p>Active Connection</p>
    </div>
  );
};

export default Live;
