import React from "react";

const Card = ({ event, index, userName }) => {
  const eTitle = event.eventTitle;
  let cardClass;
  switch (eTitle) {
    case "Airport":
      cardClass = "blue-glow card";
      break;
    case "Keyroom":
      cardClass = "yellow-glow card";
      break;
    case "Photos":
      cardClass = "pink-glow card";
      break;
    case "Inprocessing":
      cardClass = "green-glow card";
      break;
    case "Graduation":
      cardClass = "purple-glow card";
      break;
    case "Department Help":
      cardClass = "orange-glow card";
      break;
    case "After Hours":
      cardClass = "aqua-glow card";
      break;
    default:
      cardClass = null;
  }

  return (
    <div
      style={
        index === 0
          ? {
              marginTop: 0,
            }
          : {}
      }
      className={cardClass}
    >
      {}
      <h3 className="event-title">{event.eventTitle}</h3>
      <div className="event-users">
        {event.assigned.map((emp, idx) => {
          if (emp.user === userName) {
            return (
              <h4 key={idx} className="user-task">
                {emp.task}
              </h4>
            );
          }
          return "";
        })}
      </div>
      <p style={{ minWidth: "60%" }}>{event.info}</p>
      <span className="card-date">{new Date(event.date).toDateString()}</span>
    </div>
  );
};

export default Card;
