import React, { useState } from "react";
import EventEdit from "../EventEdit";
import AdminCard from "./AdminCard";

const AdminCards = ({
  allEvents,
  setAllEvents,
  currentActiveDate,
  adminStorage,
  setAdminStorage,
  socket,
}) => {
  const calDate = new Date(currentActiveDate);
  calDate.setHours(0, 0, 0, 0);
  calDate.setDate(1);

  const [eventEditer, setEventEditer] = useState({ open: false });

  const dateRenderArray = allEvents.filter((item) => {
    return new Date(item.date).setDate(1) === new Date(calDate).setDate(1);
  });

  return (
    <div className="card-container">
      {dateRenderArray.map((event, i) => (
        <AdminCard
          key={i}
          event={event}
          index={i}
          id={event._id}
          allEvents={allEvents}
          setAllEvents={setAllEvents}
          socket={socket}
          setEventEditer={setEventEditer}
          adminStorage={adminStorage}
          setAdminStorage={setAdminStorage}
        />
      ))}{" "}
      {eventEditer.open && (
        <EventEdit
          eventEditer={eventEditer}
          setEventEditer={setEventEditer}
          socket={socket}
          adminStorage={adminStorage}
          setAdminStorage={setAdminStorage}
        />
      )}
    </div>
  );
};
export default AdminCards;
