import React, { useState } from "react";

const Days = ({
  currentMonthData,
  userInfo,
  setUserInfo,
  allEvents,
  setShowModal,
}) => {
  const cWorkingMonth = currentMonthData.currentADate.getMonth();
  const cWorkingYear = currentMonthData.currentADate.getFullYear();
  const date = new Date();
  const currentDay = date.toDateString();
  const requestingDates = userInfo.requesteddates;

  const [popUp, setPopUp] = useState({
    open: false,
    requestArr: requestingDates,
  });

  const datesWithEventsArr = (d) => {
    let cWD = currentMonthData.daysInCurrentAMonth;
    const eventsArr = () => {
      if (userInfo.admin) {
        return allEvents;
      } else {
        return userInfo.eventdates;
      }
    };
    // [...cWD, { ...(cWD[3].events = [...cWD[3].events, "iurj"]) }]  set complex state
    eventsArr().forEach((ev) => {
      let idx = cWD.findIndex((d) => {
        return (
          new Date(d.id).toDateString() === new Date(ev.date).toDateString()
        );
      });
      if (idx >= 0 && !cWD[idx].events.includes(ev.eventTitle)) {
        cWD[idx].events.push(ev.eventTitle);
      } else {
        return;
      }
    });
    return cWD;
  };

  // converts d to date string using month and year in state
  const getDayValue = (d) => {
    let dayValue = new Date();
    dayValue.setMonth(cWorkingMonth);
    dayValue.setFullYear(cWorkingYear);
    dayValue.setDate(d);
    return dayValue;
  };

  //check if num exist in arr
  const checked = (num, arr) => {
    const date = new Date(cWorkingYear, cWorkingMonth, num);
    return arr.some(
      (item) => date.toDateString() === new Date(item.date).toDateString()
    );
  };

  //returns users events array
  const checkDate = (day) => {
    let dateToCheck = new Date(cWorkingYear, cWorkingMonth, day).toDateString();
    if (userInfo.admin) {
      if (checked(day, allEvents)) {
        return currentMonthData.daysInCurrentAMonth.filter((item) => {
          return new Date(item.id).toDateString() === dateToCheck;
        })[0].events;
      } else {
        return [];
      }
    } else {
      if (checked(day, userInfo.eventdates)) {
        return currentMonthData.daysInCurrentAMonth.filter((item) => {
          return new Date(item.id).toDateString() === dateToCheck;
        })[0].events;
      } else {
        return [];
      }
    }
  };

  // returns day of the week
  const dayOfWeek = (dt) => {
    return new Date(dt).toDateString().slice(0, 3);
  };

  //returns off if user requesteddates array contains dt
  const isOff = (dt) => {
    if (userInfo && userInfo.requesteddates) {
      if (userInfo.requesteddates.includes(String(dt))) {
        return "off";
      } else {
        return;
      }
    }
  };

  const handleDateClick = (e) => {
    let val = e.target.firstChild.value;
    // const twoWeekDate = new Date(Date.now() + 12096e5);
    const twoWeekDate = new Date(Date.now());
    if (!userInfo.admin) {
      if (
        !userInfo.requesteddates.includes(val) &&
        !popUp.requestArr.includes(val)
      ) {
        if (new Date(val) >= twoWeekDate) {
          setPopUp((prev) => ({
            ...prev,
            requestArr: [...prev.requestArr, val],
          }));
          e.target.classList.add("selected");
        } else {
          setShowModal(() => ({ open: true, date: val }));
          setTimeout(() => {
            setShowModal(() => ({
              open: false,
              date: null,
            }));
          }, 1000);
        }
      } else {
        setPopUp((prev) => ({ ...prev, open: true, d: val, evt: e }));
      }
    } else {
      let eDate = new Date(val);
      let eventDate = eDate.toDateString().slice(3);

      const getAvailableUsers = async () => {
        const resp = await fetch(
          "https://node.hadsraddad.com/api/getAvailable",
          {
            method: "POST",
            body: JSON.stringify({
              date: String(eDate),
            }),
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
            },
          }
        );
        const userArr = await resp.json();
        setShowModal(() => ({
          open: true,
          date: eventDate,
          availableUsers: userArr,
        }));
      };
      getAvailableUsers();
    }
  };

  const requestDays = (e) => {
    e.preventDefault();
    let userName = userInfo.userName;
    let requesting = popUp.requestArr;
    // console.log(requestDays);
    for (let i = 0; requesting.length > i; i++) {
      if (requesting[i].includes("CST")) {
        requesting[i].replace("CST", "Central Standard Time");
      }
    }

    const sendRequestedDates = async () => {
      const resp = await fetch("https://node.hadsraddad.com/api/askOff", {
        method: "POST",
        body: JSON.stringify({
          userName: userName,
          date: requesting,
        }),
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });
      const json = await resp.json();
      setUserInfo((prevInfo) => ({
        ...prevInfo,
        requesteddates: json,
      }));
    };
    sendRequestedDates();
  };

  const removeDate = (evnt) => {
    evnt.target.classList.remove("selected");
    evnt.target.classList.add("removed");
    let reqdates = popUp.requestArr;
    let idx = reqdates.indexOf(popUp.d);
    reqdates.splice(idx, 1);
    setPopUp((prev) => ({ ...prev, open: false }));
    requestDays(evnt);
  };

  return (
    <div className="middle">
      <div className="ul-box">
        <div className="under-line"></div>
      </div>
      <div className="days">
        {datesWithEventsArr(currentMonthData.currentADate).map((day) => {
          if (day.id.toDateString() === currentDay) {
            return (
              <div className={dayOfWeek(day.id) + " today"} key={day.id}>
                <input type="hidden" value={getDayValue(day.day)} />
                {day.day}
              </div>
            );
          }

          const dayStyle = (d) => {
            let arr = checkDate(d);
            let colorArr = [];
            for (let i = 0; i < arr.length; i++) {
              if (arr[i] === "Airport") {
                colorArr.push("#002ffe"); //blue
              }
              if (arr[i] === "Inprocessing") {
                colorArr.push("#00fe00"); //green
              }
              if (arr[i] === "Photos") {
                colorArr.push("#fe00fe"); //pink
              }
              if (arr[i] === "Graduation") {
                colorArr.push("#a100fe"); //purple
              }
              if (arr[i] === "Keyroom") {
                colorArr.push("#defe47"); //yellow
              }
              if (arr[i] === "After Hours") {
                colorArr.push("#47feef"); //aqua
              }
              if (arr[i] === "Department Help") {
                colorArr.push("#fe7b00"); //orange
              }
            }

            if (colorArr.length === 1) {
              return {
                style: {
                  border: "3px solid" + colorArr[0],
                  boxShadow: "0 0 7px" + colorArr[0],
                },
                class: "",
              };
            }

            if (colorArr.length === 2) {
              return {
                style: {
                  borderTop: "3px solid" + colorArr[0],
                  borderRight: "3px solid" + colorArr[0],
                  borderBottom: "3px solid" + colorArr[1],
                  borderLeft: "3px solid" + colorArr[1],
                  boxShadow:
                    "0.5px -0.5px 5px" +
                    colorArr[0] +
                    ", 0.5px 0.5px 5px" +
                    colorArr[1],
                },
                class: "",
              };
            }

            if (colorArr.length > 2) {
              return { style: {}, class: "rainbow" };
            }
            return { style: {}, class: "" };
          };

          const assignClass = (d) => {
            let arr = checkDate(d);
            console.log(arr);
            return arr.length === 2 &&
              arr.includes("Airport") &&
              arr.includes("Inprocessing")
              ? "blue-green-glow"
              : arr.length === 2 &&
                arr.includes("Airport") &&
                arr.includes("Photos")
              ? "blue-pink-glow"
              : arr.length === 2 &&
                arr.includes("Airport") &&
                arr.includes("Graduation")
              ? "blue-purple-glow"
              : arr.length === 2 &&
                arr.includes("Airport") &&
                arr.includes("Keyroom")
              ? "blue-yellow-glow"
              : arr.length === 2 &&
                arr.includes("Photos") &&
                arr.includes("Inprocessing")
              ? "green-pink-glow"
              : arr.length === 2 &&
                arr.includes("Inprocessing") &&
                arr.includes("Keyroom")
              ? "green-yellow-glow"
              : arr.length === 2 &&
                arr.includes("Photos") &&
                arr.includes("Keyroom")
              ? "pink-yellow-glow"
              : arr.length > 4
              ? "rainbow"
              : arr.includes("Airport")
              ? "blue-glow"
              : arr.includes("Keyroom")
              ? "yellow-glow"
              : arr.includes("Photos")
              ? "pink-glow"
              : arr.includes("Inprocessing")
              ? "green-glow"
              : arr.includes("Graduation")
              ? "purple-glow"
              : arr.includes("After Hours")
              ? "aqua-glow"
              : arr.includes("Department Help")
              ? "orange-glow"
              : "";
          };

          return (
            <div
              style={dayStyle(day.day).style}
              className={`day ${dayOfWeek(day.id)} ${isOff(day.id)} ${
                dayStyle(day.day).class
              }`}
              onClick={handleDateClick}
              key={day.id}
            >
              <input type="hidden" value={day.id} />
              {day.day}
            </div>
          );
        })}
      </div>
      {!userInfo.admin && (
        <div className="button-container">
          <button className="request" id="request" onClick={requestDays}>
            Submit Unavailable Dates
          </button>
        </div>
      )}
      {!userInfo.admin && popUp.open && (
        <div className="modal">
          <h2>Remove {new Date(popUp.d).toDateString()}?</h2>
          <p className="yes-no" onClick={() => removeDate(popUp.evt)}>
            yes
          </p>
          <p
            className="yes-no"
            onClick={() => setPopUp((prev) => ({ ...prev, open: false }))}
          >
            no
          </p>
        </div>
      )}
    </div>
  );
};

export default Days;
