import React from "react";

const AllUsers = ({
  setAdminInfo,
  setAllEvents,
  allEvents,
  setAdminStorage,
  setOpen,
  userList,
}) => {
  const getUsersData = async (name, email) => {
    console.log("requesting user data");
    let user = { name: name, email: email };

    const resp = await fetch("https://node.hadsraddad.com/api/getUserEvents", {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    });
    const json = await resp.json();
    json.eventdates.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
    if (navigator.vendor.includes("Apple")) {
      for (let i = 0; json.requesteddates.length > i; i++) {
        json.requesteddates[i] = json.requesteddates[i].replace(
          "Central Standard Time",
          "CST"
        );
      }
    } else {
      for (let i = 0; json.requesteddates.length > i; i++) {
        json.requesteddates[i] = json.requesteddates[i].replace(
          "CST",
          "Central Standard Time"
        );
      }
    }
    setAdminStorage((prev) => ({
      ...prev,
      data: allEvents,
      userLoaded: true,
      user: json.userName,
    }));
    setAdminInfo((prev) => ({ ...prev, requesteddates: json.requesteddates }));
    setAllEvents(() => json.eventdates);
    setOpen(() => false);
  };

  const loadUser = (user, email) => {
    getUsersData(user, email);
  };

  return (
    <div className="pop-out">
      <ul>
        {userList.map((usr) => {
          return (
            <li
              className="user-item"
              onClick={() => loadUser(usr.userName, usr.email)}
              key={usr._id}
            >
              {usr.FullName}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AllUsers;
