import React from "react";
import Card from "./Card";

const Cards = ({ events, currentActiveDate, userName }) => {
  const calDate = new Date(currentActiveDate);
  calDate.setHours(0, 0, 0, 0);
  calDate.setDate(1);

  const dateRenderArray = events.filter((item) => {
    return new Date(item.date).setDate(1) === new Date(calDate).setDate(1);
  });

  return (
    <div className="card-container">
      {dateRenderArray.map((event, i) => (
        <Card key={event._id} event={event} index={i} userName={userName} />
      ))}{" "}
    </div>
  );
};
export default Cards;
