import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import socketIOClient from "socket.io-client";
import User from "../components/User/User";
import Admin from "../components/Admin/Admin";

const Index = () => {
  const { user, isAuthenticated } = useAuth0();
  const [userInfo, setUserInfo] = useState({
    eventdates: [],
    admin: false,
    loaded: false,
  });

  const [socket, setSocket] = useState({ connected: false });
  const [events, setEvents] = useState([{ date: Date.now(), eventTitle: "" }]);

  useEffect(() => {
    console.log("Index useEffect ran");
    setSocket(() => socketIOClient("https://node.hadsraddad.com"));
    const getData = async () => {
      const resp = await fetch(
        "https://node.hadsraddad.com/api/getUserEvents",
        {
          method: "POST",
          body: JSON.stringify(user),
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      );
      const json = await resp.json();
      console.log("loading user: ", json.userName);
      if (json.admin) {
        console.log("loading admin data");
        const eventsData = await fetch(
          "https://node.hadsraddad.com/api/getEvents"
        );
        const eventsJson = await eventsData.json();
        eventsJson.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        setEvents(eventsJson);
      } else {
        const userEvents = await fetch(
          "https://node.hadsraddad.com/api/updateEvents",
          {
            method: "POST",
            body: JSON.stringify({
              userName: json.userName,
            }),
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
            },
          }
        );
        const userEventsJson = await userEvents.json();
        userEventsJson.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        json.eventdates = userEventsJson;
        for (let i = 0; json.requesteddates.length > i; i++) {
          if (json.requesteddates[i].includes("CST")) {
            json.requesteddates[i].replace("Central Standard Time", "CST");
          }
        }
      }
      json.loaded = true;
      setUserInfo((prev) => json);
    };
    getData();
  }, [user]);

  if (!userInfo.admin && userInfo.loaded && isAuthenticated) {
    console.log("Load User Component");
    return (
      <User userInfo={userInfo} setUserInfo={setUserInfo} socket={socket} />
    );
  } else if (userInfo.admin && userInfo.loaded && isAuthenticated) {
    console.log("Load Admin Component");
    if (socket.connected) {
      console.log("Socket Connected");
    }
    return (
      <Admin
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        socket={socket}
        events={events}
        setEvents={setEvents}
      />
    );
  } else {
    return (
      <div className="loading">
        <div className="loader"></div>
        <h1>Loading</h1>
      </div>
    );
  }
};
export default withAuthenticationRequired(Index, {
  onRedirecting: () => (
    <div className="loading">
      <div className="loader"></div>
      <h1>Authenticating{console.log("authenticating")}</h1>
    </div>
  ),
});
