import React, { useState } from "react";
import Calendar from "../Cal/Calendar";
import AdminCards from "./Cards/AdminCards";
import LogoutButton from "../LogoutButton";
import Menu from "./Admin Features/Menu";
import Event from "./Event";
import Live from "../Live";

const Admin = ({ userInfo, setUserInfo, socket, events }) => {
  const date = new Date();

  const [showModal, setShowModal] = useState({ open: false, date: null });
  const [adminInfo, setAdminInfo] = useState(userInfo);
  const [adminStorage, setAdminStorage] = useState({
    data: "",
    userLoaded: false,
  });
  const [allEvents, setAllEvents] = useState(events);

  // initalize currentActiveDate state
  const [currentActiveDate, setCurrentActiveDate] = useState(date);

  return (
    <div className={`App ${adminStorage.userLoaded ? "user-loaded" : ""}`}>
      <Menu
        setAdminInfo={setAdminInfo}
        adminStorage={adminStorage}
        setAdminStorage={setAdminStorage}
        setAllEvents={setAllEvents}
        allEvents={allEvents}
      />
      {socket.connected && <Live admin={userInfo.admin} />}
      <Calendar
        userInfo={adminInfo}
        userLoaded={adminStorage.userLoaded}
        setUserInfo={setAdminInfo}
        currentActiveDate={currentActiveDate}
        setCurrentActiveDate={setCurrentActiveDate}
        allEvents={allEvents}
        setShowModal={setShowModal}
      />
      <AdminCards
        allEvents={allEvents}
        setAllEvents={setAllEvents}
        adminStorage={adminStorage}
        setAdminStorage={setAdminStorage}
        currentActiveDate={currentActiveDate}
        socket={socket}
      />
      {showModal.open && (
        <Event
          allEvents={allEvents}
          setAllEvents={setAllEvents}
          showModal={showModal}
          setShowModal={setShowModal}
          socket={socket}
        />
      )}
      {/* <h1 onClick={() => socket.emit("event created", "e create")}>
        emit event created
      </h1> */}
      <LogoutButton />
    </div>
  );
};

export default Admin;
