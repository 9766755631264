import React from "react";
import { Switch, Route } from "react-router-dom";
import Index from "./pages/Index";

import "./styles/app.scss";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact>
          <Index />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
