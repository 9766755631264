import React, { useEffect, useState } from "react";

const Select = ({ inplace, available, data }) => {
  return (
    <div>
      <label>{data}</label>
      <select data={data} name="user">
        <option>{inplace}</option>
        {available.map((person) => {
          return (
            <option key={person._id}>
              {!person.FullName ? person.userName : person.FullName}
            </option>
          );
        })}
      </select>
    </div>
  );
};
const EventEdit = ({ eventEditer, setEventEditer, socket }) => {
  const [available, setAvailable] = useState([]);
  const event = eventEditer.event;
  useEffect(() => {
    console.log("edit event use effect");
    let eDate = new Date(event.date);
    const getAvailableUsers = async () => {
      const resp = await fetch("https://node.hadsraddad.com/api/getAvailable", {
        method: "POST",
        body: JSON.stringify({
          date: String(eDate),
        }),
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });
      const userArr = await resp.json();
      setAvailable(() => userArr);
    };
    getAvailableUsers();
  }, [event]);

  const closeForm = () => {
    setEventEditer((prev) => ({ open: false }));
  };

  const submitUpdate = (e) => {
    e.preventDefault(e);
    let form = e.target.form;
    let userList = [];
    let users = Array.from(form.user);
    users.forEach((i) => {
      userList.push({ user: i.value, task: i.attributes.data.value });
    });
    event.assigned = userList;
    const ChangeEvent = async () => {
      await fetch("https://node.hadsraddad.com/api/ChangeEvent", {
        method: "POST",
        body: JSON.stringify(event),
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });
      // const userArr = await resp.json();
    };
    ChangeEvent();
    setEventEditer(() => ({
      open: false,
    }));
    socket.emit("event created", "updated");
  };

  const displayDate = new Date(event.date).toDateString();
  return (
    <div className="modal">
      <button className="close-button" onClick={closeForm}>
        X
      </button>
      <h1>{displayDate}</h1>
      <form>
        <label>USERS</label>
        {event.assigned.map((tsk, idx) => {
          return (
            <Select
              key={idx}
              inplace={tsk.user}
              available={available}
              data={tsk.task}
            />
          );
        })}
        <button onClick={submitUpdate}>Submit</button>
      </form>
    </div>
  );
};

export default EventEdit;
