import React, { useState } from "react";
import Month from "./Month";
import Days from "./Days";

const Calendar = ({
  setUserInfo,
  userInfo,
  currentActiveDate,
  setCurrentActiveDate,
  allEvents,
  setShowModal,
  userLoaded,
}) => {
  let date = new Date();

  // return array containing objects for each day of the month {id: date, day: day#, events: [empty]}
  const findDaysInMonth = (d) => {
    let i = 1;
    let lastDay;
    lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
    let daysInMonth = [];
    while (i <= lastDay) {
      let dt = new Date();
      dt.setMonth(d.getMonth());
      dt.setFullYear(d.getFullYear());
      dt.setHours(0, 0, 0, 0);
      dt.setDate(i);
      daysInMonth.push({ id: dt, day: i, events: [] });
      i++;
    }
    return daysInMonth;
  };

  const cActiveMonth = new Date(currentActiveDate).getMonth();
  const cActiveYear = new Date(currentActiveDate).getFullYear();

  const findEventsOnSameDay = (d) => {
    let cWD = findDaysInMonth(d);
    const eventsArr = () => {
      if (userInfo.admin) {
        return allEvents;
      } else {
        return userInfo.eventdates;
      }
    };

    eventsArr().forEach((ev) => {
      let idx = cWD.findIndex((d) => {
        return (
          new Date(d.id).toDateString() === new Date(ev.date).toDateString()
        );
      });
      if (idx >= 0 && !cWD[idx].events.includes(ev.eventTitle)) {
        cWD[idx].events.push(ev.eventTitle);
      } else {
        return;
      }
    });
    return cWD;
  };

  // initalize currentMonthData state and set as object using above vars
  const [currentMonthData, setcurrentMonthData] = useState({
    todaysDate: new Date(),
    currentADate: date,
    daysInCurrentAMonth: findEventsOnSameDay(date),
  });

  // progress current Active calendar sets current
  const handlePrevClick = () => {
    const prevDate = new Date(
      currentMonthData.currentADate.setMonth(cActiveMonth - 1)
    );

    setCurrentActiveDate(() => prevDate);
    setcurrentMonthData((prev) => ({
      ...prev,
      daysInCurrentAMonth: findEventsOnSameDay(prevDate),
    }));
  };

  const handleNextClick = () => {
    const nextDate = new Date(
      currentMonthData.currentADate.setMonth(cActiveMonth + 1)
    );

    setCurrentActiveDate(() => nextDate);

    setcurrentMonthData((prev) => ({
      ...prev,
      daysInCurrentAMonth: findEventsOnSameDay(nextDate),
    }));
  };

  return (
    <div
      className="calendar"
      style={userInfo.admin ? {} : { marginTop: "20px" }}
    >
      <div className={`${userLoaded ? "user-cal-loaded" : ""} month`}>
        <p
          style={{
            width: "100%",
            marginTop: "1.5%",
          }}
        >
          {new Date().toDateString()}{" "}
        </p>
        <i className="fas fa-angle-left prev" onClick={handlePrevClick}></i>
        <Month month={cActiveMonth} year={cActiveYear} />
        <i className="fas fa-angle-right next" onClick={handleNextClick}></i>
      </div>
      <div className="days-of-week">
        <div>Sun</div>
        <div>Mon</div>
        <div>Tue</div>
        <div>Wed</div>
        <div>Thu</div>
        <div>Fri</div>
        <div>Sat</div>
      </div>
      <Days
        currentMonthData={currentMonthData}
        setcurrentMonthData={setcurrentMonthData}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        allEvents={allEvents}
        setShowModal={setShowModal}
      />
    </div>
  );
};

export default Calendar;
