import React from "react";

const NameForm = ({ userInfo, setUserInfo }) => {
  const updateUser = async (userName, fullName) => {
    let user = { userName: userName, FullName: fullName };
    console.log(user);
    const resp = await fetch("https://node.hadsraddad.com/api/updateName", {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
    });
    const json = await resp.json();
    console.log(json);
  };

  const submitName = (e) => {
    e.preventDefault();
    const form = e.target.form;
    const first = form.first.value;
    const last = form.last.value;
    if (first === "") {
      alert("Please enter first name");
    } else if (last === "") {
      alert("Please enter last name");
    } else {
      const full = first + " " + last;
      setUserInfo((prev) => ({
        ...prev,
        FullName: full,
        userName: full,
        newuser: false,
      }));
      updateUser(userInfo.userName, full);
    }
  };

  return (
    <div className="modal">
      <form>
        <label>First Name:</label>
        <input name="first" required type="text"></input>
        <label>Last Name:</label>
        <input name="last" required type="text"></input>
        <button onClick={submitName} className="submit-button">
          Proceed
        </button>
      </form>
    </div>
  );
};

export default NameForm;
