import React, { useRef, useState } from "react";

const Select = ({ showModal, data }) => {
  return (
    <select data={data} name="user">
      <option>{null}</option>
      {showModal.availableUsers.map((person) => (
        <option key={person._id} value={person.userName}>
          {!person.FullName ? person.userName : person.FullName}
        </option>
      ))}
    </select>
  );
};

const Event = ({ setAllEvents, showModal, setShowModal, socket }) => {
  const eventType = useRef(null);

  const [inputList, setInputList] = useState([]);

  const onAddBtnClick = (data) => {
    setInputList((prev) => [
      ...prev,
      <Select
        showModal={showModal}
        data={data}
        key={inputList.length}
      ></Select>,
    ]);
  };

  const submitEvent = (e) => {
    e.preventDefault();
    let form = e.target.form;
    let title = form.title.value;
    let users = Array.from(form.user);
    let assignedUsers = [];
    for (let i = 0; i < users.length; i++) {
      // assignedUsers.push(users[i].value);
      assignedUsers.push({
        user: users[i].value,
        task: users[i].attributes.data.value,
      });
    }
    let info = form.notes.value;
    let date = new Date(showModal.date);

    const sendEvent = async () => {
      const resp = await fetch("https://node.hadsraddad.com/api/createEvent", {
        method: "POST",
        body: JSON.stringify({
          title: title,
          date: date,
          assigned: assignedUsers,
          info: info,
        }),
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      });
      const json = await resp.json();
      json.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });
      socket.emit("event created", json);
      setAllEvents(json);
    };
    setShowModal(() => ({ open: false, date: null }));
    sendEvent();
  };

  const hideModal = (e) => {
    if (showModal.open) {
      setShowModal(() => ({ open: false, date: null }));
    }
  };

  const updateForm = () => {
    let eType = eventType.current.value;
    setShowModal((prevState) => ({ ...prevState, fType: eType }));
  };

  return (
    <div className="modal">
      <form>
        <button className="close-button" onClick={hideModal}>
          X
        </button>
        <h2 className="date-banner">{showModal.date}</h2>
        <select ref={eventType} onChange={updateForm} name="title">
          <option value={null}>SELECT AN EVENT TYPE</option>
          <option>Airport</option>
          <option>Inprocessing</option>
          <option>Photos</option>
          <option>Graduation</option>
          <option>After Hours</option>
          <option>Department Help</option>
          <option>Keyroom</option>
        </select>
        {showModal.fType === "Airport" ? (
          <div className="loaded-form">
            <label>POC</label>
            <Select showModal={showModal} data="POC" />
            <label>Staff</label>
            <Select showModal={showModal} data="staff" />
            {inputList.filter((i) => i.props.data === "staff")}
            <button
              onClick={(e) => {
                e.preventDefault();
                onAddBtnClick("staff");
              }}
            >
              add more...
            </button>
            <label>Notes:</label>
            <textarea name="notes" />
            <button className="submit-button" onClick={submitEvent}>
              Submit Event
            </button>
          </div>
        ) : showModal.fType === "Photos" ? (
          <div className="loaded-form">
            <label>Bldg 61</label>
            <Select showModal={showModal} data="Bldg 61" />
            {inputList.filter((i) => i.props.data === "Bldg 61")}
            <button
              onClick={(e) => {
                e.preventDefault();
                onAddBtnClick("Bldg 61");
              }}
            >
              add more...
            </button>
            <label>NTF</label>
            <Select showModal={showModal} data="NTF" />
            {inputList.filter((i) => i.props.data === "NTF")}
            <button
              onClick={(e) => {
                e.preventDefault();
                onAddBtnClick("NTF");
              }}
            >
              add more...
            </button>
            <label>Notes:</label>
            <textarea name="notes" />
            <button className="submit-button" onClick={submitEvent}>
              Submit Event
            </button>
          </div>
        ) : showModal.fType === "Graduation" ? (
          <div className="loaded-form">
            <label>Bldg 61</label>
            <Select showModal={showModal} data="Bldg 61" />
            {inputList.filter((i) => i.props.data === "Bldg 61")}
            <button
              onClick={(e) => {
                e.preventDefault();
                onAddBtnClick("Bldg 61");
              }}
            >
              add more...
            </button>
            <label>NTF</label>
            <Select showModal={showModal} data="NTF" />
            {inputList.filter((i) => i.props.data === "NTF")}
            <button
              onClick={(e) => {
                e.preventDefault();
                onAddBtnClick("NTF");
              }}
            >
              add more...
            </button>
            <label>Notes:</label>
            <textarea name="notes" />
            <button className="submit-button" onClick={submitEvent}>
              Submit Event
            </button>
          </div>
        ) : showModal.fType === "After Hours" ? (
          <div className="loaded-form">
            <label>Bldg 61</label>
            <Select showModal={showModal} data="Bldg 61" />
            {inputList.filter((i) => i.props.data === "Bldg 61")}
            <button
              onClick={(e) => {
                e.preventDefault();
                onAddBtnClick("Bldg 61");
              }}
            >
              add more...
            </button>
            <label>NTF</label>
            <Select showModal={showModal} data="NTF" />
            {inputList.filter((i) => i.props.data === "NTF")}
            <button
              onClick={(e) => {
                e.preventDefault();
                onAddBtnClick("NTF");
              }}
            >
              add more...
            </button>
            <label>Notes:</label>
            <textarea name="notes" />
            <button className="submit-button" onClick={submitEvent}>
              Submit Event
            </button>
          </div>
        ) : showModal.fType === "Department Help" ? (
          <div className="loaded-form">
            <label>Staff</label>
            <Select showModal={showModal} data="staff" />
            {inputList.filter((i) => i.props.data === "staff")}
            <button
              onClick={(e) => {
                e.preventDefault();
                onAddBtnClick("staff");
              }}
            >
              add more...
            </button>
            <label>Notes:</label>
            <textarea name="notes" />
            <button className="submit-button" onClick={submitEvent}>
              Submit Event
            </button>
          </div>
        ) : showModal.fType === "Inprocessing" ? (
          <div className="loaded-form">
            <label>Coordinator</label>
            <Select showModal={showModal} data="Coordinator" />
            <label>Clerk</label>
            <Select showModal={showModal} data="Clerk" />
            {inputList.filter((i) => i.props.data === "Clerk")}
            <button
              onClick={(e) => {
                e.preventDefault();
                onAddBtnClick("Clerk");
              }}
            >
              add more...
            </button>
            <label>Screener</label>
            <Select showModal={showModal} data="Screener" />
            {inputList.filter((i) => i.props.data === "Screener")}
            <button
              onClick={(e) => {
                e.preventDefault();
                onAddBtnClick("Screener");
              }}
            >
              add more...
            </button>
            <label>Runner</label>
            <Select showModal={showModal} data="Runner" />
            {inputList.filter((i) => i.props.data === "Runner")}
            <button
              onClick={(e) => {
                e.preventDefault();
                onAddBtnClick("Runner");
              }}
            >
              add more...
            </button>
            <label>Notes:</label>
            <textarea name="notes" />
            <button className="submit-button" onClick={submitEvent}>
              Submit Event
            </button>
          </div>
        ) : showModal.fType === "Keyroom" ? (
          <div className="loaded-form">
            <label>Staff</label>
            <Select showModal={showModal} data="staff" />
            <Select showModal={showModal} data="staff" />
            {inputList.filter((i) => i.props.data === "staff")}
            <button
              onClick={(e) => {
                e.preventDefault();
                onAddBtnClick("staff");
              }}
            >
              add more...
            </button>
            <label>Notes:</label>
            <textarea name="notes" />
            <button className="submit-button" onClick={submitEvent}>
              Submit Event
            </button>
          </div>
        ) : (
          ""
        )}
      </form>
    </div>
  );
};

export default Event;
